<template>
  <div class="page-404">
    <img src="@/assets/images/404.png" alt="">
    <p>Page not found!</p>
    <el-button
      type="primary"
      @click="$router.back()">
      <em class="el-icon-back"></em>
      {{ $t('back') }}
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'page404'
}
</script>

<style lang="scss" scoped>
.page-404 {
  height: 100vh;
  width: 100vw;
  @include flex-center-column;

  img {
    width: 20%;
    margin-bottom: 20px;
  }

  p {
    font-size: 36px;
    font-weight: bold;
    color: #606266;
  }

  .el-button {
    width: 100px;
  }
}
</style>
